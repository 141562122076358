module.exports = {
  API_URL: 'https://api-dev.nimway.com',
  AUTH_URL: 'https://auth-dev.nimway.com',
  GRAPHQL: 'https://api-dev.nimway.com/graphql',
  SENSOR_EVENTS_URL: 'https://api-dev.nimway.com/sensorevents/',
  DOMAIN_IMPORT: 'https://api-dev.nimway.com/admin/domainimport/jobs',
  DOMAIN_VERSIONS: 'https://api-dev.nimway.com/admin/domainimport/domainversions',
  FLOORPLAN_APP_EVENTS: 'https://proximity-dev.nimway.com/floorplanappevents',
  DEVICE_ACTIONS: 'https://gateway-dev.nimway.com/deviceaction',
  USER_URL: 'https://user-dev.nimway.com',
  // API_URL: 'https://api.nimway.com',
  // AUTH_URL: 'https://auth.smartoffice.incubation.io',
  // GRAPHQL: 'https://api.nimway.com/graphql',
  // SENSOR_EVENTS_URL: 'https://api.nimway.com/sensorevents',
  // DOMAIN_IMPORT: 'https://api.smartoffice.incubation.io/domainimport/jobs',
  // FLOORPLAN_APP_EVENTS: 'https://proximity.smartoffice.incubation.io/floorplanappevents',
}
